<template>
    <div>
        <Navbar :page="!$store.state.user.cliente ? 'Gestão de Projetos' : 'Andamento do Projeto'" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    <div class="px-3 pb-4">
                        <div class="grid grid-cols-12 gap-x-2 gap-y-4">
                            <div class="col-span-12 ">
                                <div class="flex items-center">
                                <label for="ref" class="mr-1">
                                    Ref da tarefa
                                </label>
                                <input v-model="form.ref" id="ref" type="text" class="w-10 focus:ring-yellow-400 focus:border-yellow-400 shadow-sm sm:text-sm border-gray-300 rounded-md">
                                </div>
                            </div>
                            <div class="col-span-12 md:col-span-3">
                                <label for="nome" class="block text-sm font-medium">Nome da tarefa</label>
                                <textarea v-model="form.nome" rows="1" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                            </div>
                            <div class="col-span-12 md:col-span-3">
                                <label for="status" class="block text-sm font-medium">Status</label>
                                <select required v-model="form.status" name="status" id="status" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option value="Pendente">Pendente</option>
                                    <option value="Programada">Programada</option>
                                    <option value="Em Andamento">Em Andamento</option>
                                    <option value="Concluido">Concluido</option>
                                    <option value="Atrasada">Atrasada</option>
                                </select>
                            </div>
                            <div class="col-span-12 md:col-span-3">
                                <label for="inicio" class="block text-sm font-medium">Data Início</label>
                                <input type="date" v-model="form.inicio" id="inicio" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                            </div>  
                            <div class="col-span-12 md:col-span-3">
                                <label for="fim" class="block text-sm font-medium">Data Fim</label>
                                <input type="date" v-model="form.fim" id="fim" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                            </div>
                            <div class="col-span-12 md:col-span-6">
                                <span class="text-sm font-medium">Nível de relevância</span>
                                <div class="mt-2">
                                    <label for="Baixo" class="inline-flex items-center">
                                        <input type="radio" class="form-radio" id="Baixo" name="nivelUrgencia" v-model="form.nivelUrgencia" value="Baixo">
                                        <span class="ml-2">1 - Baixo</span>
                                    </label>
                                    <label for="Médio" class="inline-flex items-center ml-6">
                                        <input type="radio" class="form-radio" id="Médio" name="nivelUrgencia" v-model="form.nivelUrgencia" value="Médio">
                                        <span class="ml-2">2 - Médio</span>
                                    </label>
                                    <label for="Alto" class="inline-flex items-center ml-6">
                                        <input type="radio" class="form-radio" id="Alto" name="nivelUrgencia" v-model="form.nivelUrgencia" value="Alto">
                                        <span class="ml-2">3 - Alto</span>
                                    </label>
                                </div>
                            </div> 
                            <div class="col-span-6 md:col-span-6">
                                <label for="fase" class="block text-sm font-medium">Fase no projeto</label>
                                <select required v-model="form.fase" name="fase" id="fase" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="fase in fases" :key="fase._id" :value="fase.nome">{{ fase.nome }}</option>
                                </select>
                            </div>
                            <div class="col-span-12 md:col-span-6">
                                <label for="descricao" class="block text-sm font-medium">Descrição</label>
                                <textarea v-model="form.descricao" type="text" name="descricao" id="descricao" rows="4" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"></textarea>
                            </div> 
                            <div class="col-span-12 md:col-span-6">
                                <label for="refLegal" class="block text-sm font-medium">Detalhes</label>
                                <textarea v-model="form.refLegal" type="text" name="refLegal" id="refLegal" rows="4" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"></textarea>
                            </div>
                            
                            <div class="col-span-12 md:col-span-6">
                                <label for="tipo_responsavel" class="block text-sm font-medium">Perfil do responsável</label>
                                <select required v-model="form.tipo_responsavel" name="tipo_responsavel" id="tipo_responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option value="consultor">Marcar consultor como responsável</option>
                                    <option value="profissional">Profissional</option>
                                    <option value="cliente">Pessoas na empresa</option>
                                </select>
                                
                            </div>
                            <div v-if="form.tipo_responsavel === 'profissional'" class="col-span-12 md:col-span-6">
                                <label for="profissional_responsavel" class="block text-sm font-medium">Responsável</label>

                                <multiselect 
                                    v-model="form.profissional_responsavel" 
                                    label="nome" 
                                    trackBy="_id" 
                                    :options="profissionais" 
                                    id="profissional_responsavel" 
                                    :multiple="true" 
                                    :close-on-select="false" 
                                    :taggable="true" 
                                    placeholder="selecione os profissionais" 
                                    selectedLabel="selecionado" 
                                    deselectLabel="Pressione Enter para remover" 
                                    selectLabel="Pressione Enter para selecionar" 
                                    class="mt-2" 
                                />

                            </div>
                            <div v-if="form.tipo_responsavel === 'cliente'" class="col-span-12 md:col-span-6">
                                <label for="cliente_responsavel" class="block text-sm font-medium">Responsável</label>

                                <multiselect 
                                    v-model="form.cliente_responsavel" 
                                    label="nome" 
                                    trackBy="_id" 
                                    :options="pessoas" 
                                    id="cliente_responsavel" 
                                    :multiple="true" 
                                    :close-on-select="false" 
                                    :taggable="true" 
                                    placeholder="selecione os resposáveis" 
                                    selectedLabel="selecionado" 
                                    deselectLabel="Pressione Enter para remover" 
                                    selectLabel="Pressione Enter para selecionar" 
                                    class="mt-2" 
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <button @click="$modal.hide('tarefaModal')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                    </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            route: 'tarefas',
            fases: [],
            form: {
                nome: "",
                descricao: "",
                nivelUrgencia: 1,
                inicio: '',
                fim: '',
                status: "Pendente",
                refLegal: "",
                fase: "",
                tipo_responsavel: '',
                profissional_responsavel: null,
                cliente_responsavel: null
            },
            pessoas: [],
            profissionais: [],
        }
    },
    methods: {
        async save() {

             if(this.form.tipo_responsavel === 'consultor') {
                this.form.cliente_responsavel = null;
                this.form.profissional_responsavel = null;
            }

            if(this.form.tipo_responsavel === 'profissional') {
                this.form.cliente_responsavel = null;
            }

            if(this.form.tipo_responsavel === 'cliente') {
                this.form.profissional_responsavel = null;
            }

            const reqData = await this.$http.post(`/v1/${this.route}/analisar-data`, this.form);
            
            if(reqData.data > 0 ) {
                this.$confirm({
                    title: 'Atenção: Data ocupada',
                    message: `Já existe tarefa agendada para este responsável nesta data. Programar mesmo assim?`,
                    button: {
                        no: 'Não',
                        yes: 'Sim',
                    },
                    callback: async confirm => {
                        if(!confirm) return;
                        const method = this.form._id ? 'put' : 'post'
                        const req = await this.$http[method](`/v1/${this.route}`, this.form);

                        if (req.data.success) {
                            this.$vToastify.success("Salvo com sucesso!");
                            this.$router.push({path: `/projetos`});
                        } else {
                            this.$vToastify.error(req.data.err);
                        }
                    }
                })
                return 
            }

            const method = this.form._id ? 'put' : 'post'
            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.push({path: `/projetos`});
            } else {
                this.$vToastify.error(req.data.err);
            }
        }
    },
    async beforeMount() {
        const id = this.$route.params.id;

        const pessoasReq = await this.$http.post(`/v1/pessoas/list`);
        this.pessoas = pessoasReq.data.data;

        const profissionaisRef = await this.$http.post(`/v1/profissionais/listProfissionaisEmpresa`);  
        this.profissionais = profissionaisRef.data.data;

        const fasesReq = await this.$http.post(`/v1/tarefas/fases/list`, { all: true });
        this.fases = fasesReq.data.data;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
            if(this.form.inicio) this.form.inicio = this.$moment(this.form.inicio).format('DD/MM/YYYY');
            if(this.form.fim) this.form.fim = this.$moment(this.form.fim).format('DD/MM/YYYY');
        }
    },
}
</script>